import * as React from 'react';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { TestIds } from '../constants';
import { getSDK, youtubeSDK } from '../../../../providers/ScriptLoader';
import useProgress, { IProgress } from './useProgress';
import {
  IPlayer,
  IYouTubePlayer,
  IPlayerProps,
  IPlayerHandles,
} from './players.types';

const URL_REGEX =
  /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
const getVideoId = (src: string): string => src.match(URL_REGEX)![1];

const INTERVAL = 50;
const PLAYER_READYNESS_KEY = 'playVideo';
const waitForPlayerToBeReady = (
  player: IYouTubePlayer,
): Promise<IYouTubePlayer> =>
  new Promise(resolve => {
    const resolveIfReady = () => {
      if (player[PLAYER_READYNESS_KEY]) {
        resolve(player);
      } else {
        setTimeout(resolveIfReady, INTERVAL);
      }
    };
    resolveIfReady();
  });

const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  ytRef: React.MutableRefObject<any>,
  config: any,
): [() => Promise<IYouTubePlayer>, () => IYouTubePlayer | void] => {
  const [waitForPlayer, getPlayer, setPlayer] = useAsyncRef<IYouTubePlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK(youtubeSDK);
    waitForSDK
      .then((YT: any) => {
        ytRef.current = YT;
        setPlayer(new YT.Player(container.current, config) as IYouTubePlayer);
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  const waitFunc = () => waitForPlayer().then(waitForPlayerToBeReady);
  return [waitFunc, getPlayer];
};

function subscribeToPlayerEvents(
  getPlayer: () => IYouTubePlayer,
  getYT: () => any,
  {
    onPlay,
    onPause,
    onEnded,
    onProgress,
    onFirstPlay,
    onFirstEnded,
  }: Partial<IPlayerProps>,
  progress: IProgress,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
) {
  progress.subscribe(() => {
    onProgress?.(getPlayer().getCurrentTime() || 0);
  });

  return ({ data: state }: any): void => {
    const { PLAYING, PAUSED, ENDED } = getYT();
    if (state === PLAYING) {
      if (!firstPlayStarted.current) {
        firstPlayStarted.current = true;
        onFirstPlay?.();
      }

      isPlayingNow.current = true;
      onPlay?.();

      progress.update();
    }

    if (state === PAUSED) {
      isPlayingNow.current = false;
      onPause?.();

      progress.stop();
    }

    if (state === ENDED) {
      if (!firstPlayEnded.current) {
        firstPlayEnded.current = true;
        onFirstEnded?.();
      }

      isPlayingNow.current = false;
      onEnded?.();

      progress.stop();
    }
  };
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IYouTubePlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player =>
      playing ? player.playVideo() : player.pauseVideo(),
    ),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => (muted ? player.mute() : player.unMute())),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume!)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IYouTubePlayer>,
  getPlayer: () => IYouTubePlayer | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () =>
      waitForPlayer().then(player => {
        player.playVideo();
      }),
    pause: () =>
      waitForPlayer().then(player => {
        player.pauseVideo();
      }),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    stop: () =>
      waitForPlayer().then(player => {
        player.stopVideo();
      }),
    getDuration: () => {
      const player = getPlayer();
      return player && player.getDuration ? player.getDuration() : 0;
    },
    getCurrentTime: () => {
      const player = getPlayer();
      return player && player.getCurrentTime ? player.getCurrentTime() : 0;
    },
    seekTo: time =>
      waitForPlayer().then(player => {
        player.seekTo(time);
      }),
    getVolume: () => {
      const player = getPlayer();
      return player && player.getVolume ? player.getVolume() : 0;
    },
    setVolume: fraction =>
      waitForPlayer().then(player => {
        player.setVolume(fraction);
      }),
    isMuted: () => {
      const player = getPlayer();
      return player && player.isMuted ? player.isMuted() : true;
    },
    isPlaying: () => isPlayingNow.current,
    mute: () =>
      waitForPlayer().then(player => {
        player.mute();
      }),
    unMute: () =>
      waitForPlayer().then(player => {
        player.unMute();
      }),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    src,
    playing,
    muted,
    loop,
    controls,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  } = props;

  const ytRef = React.useRef<any>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const progress = useProgress();

  const videoId = getVideoId(src as string);

  const [waitForPlayer, getPlayer] = usePlayer(containerRef, ytRef, {
    width: '100%',
    height: '100%',
    videoId,
    playerVars: {
      autoplay: playing ? 1 : 0,
      mute: muted ? 1 : 0,
      controls: controls ? 1 : 0,
      loop: loop ? 1 : 0,
      origin:
        typeof window !== 'undefined' &&
        window.location &&
        window.location.origin,
      playsinline: 1,
      ...(loop && { playlist: videoId }),
    },
    events: {
      onReady: () => {
        onReady?.();
        onDuration?.((getPlayer() as IYouTubePlayer).getDuration());
      },
      onStateChange: subscribeToPlayerEvents(
        () => getPlayer() as IYouTubePlayer,
        () => ytRef.current.PlayerState,
        { onPlay, onPause, onEnded, onProgress, onFirstPlay, onFirstEnded },
        progress,
        isPlayingNow,
        firstPlayEnded,
        firstPlayStarted,
      ),
      onError,
    },
  });

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        onInit?.(player, 'youtube');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  return (
    <div
      className={classes.playerContainer}
      data-player-name="YouTube"
      data-testid={TestIds.youtube}
    >
      <div ref={containerRef} />
    </div>
  );
};

export default React.forwardRef(Player);
